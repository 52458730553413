import * as React from "react"
//import BabylonScene from './BabylonScene'
import "../components/layout.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import "../../node_modules/bootstrap/dist/js/bootstrap.min.js"

import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import Chip  from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert'; 
import MailIcon from '@mui/icons-material/Mail';
import Box      from '@mui/material/Box';
import List     from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText   from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { LinearProgress } from "@mui/material";

//Icons
import {MenuBook, MenuBookSharp} from '@mui/icons-material';
import {Coffee, Code, Face} from '@mui/icons-material';

//DevIcon
import '../../node_modules/devicon/devicon.min.css'

function createData(
    route,
    description,
    status,
) {
    return { route, description, status };
}

const rows = [
    createData('proj-ordersystem', 'Built with Android, ReactJS, Push Notifications, Star Printer / IPv4 & Bluetooth.  System cataloges restaurant food items imported from a json data file.  Client app sends food orders to server which prints to an industry restaurant Star printer', 'Active Development' ),
    createData('/articles', 'Various misc topics and short blogs', 'Active Development' ),
    createData('/portfolio', 'Projects and Project Presentations', 'Active Development'),
    createData('/photography', 'Art, Illustrations, Photography', 'Active Development'),
];


// styles
const pageStyles = {
    color: "#232129",
    padding: 96,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}
const headingAccentStyles = {
    color: "#663399",
}
const paragraphStyles = {
    marginBottom: 48,

    fontSize: "1.25rem",
    borderRadius: 4,
}
const listStyles = {
    marginBottom: 96,
    paddingLeft: 0,
}
const listItemStyles = {
    fontWeight: 300,
    fontSize: 24,
    maxWidth: 560,
    marginBottom: 30,
}

const linkStyle = {
    color: "#8954A8",
    fontWeight: "bold",
    fontSize: 16,
    verticalAlign: "5%",
}

const docLinkStyle = {
    ...linkStyle,
    listStyleType: "none",
    marginBottom: 24,
}

const descriptionStyle = {
    color: "#232129",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 0,
    lineHeight: 1.25,
}

const docLink = {
    text: "Documentation",
    url: "https://www.gatsbyjs.com/docs/",
    color: "#8954A8",
}

const badgeStyle = {
    color: "#fff",
    backgroundColor: "#088413",
    border: "1px solid #088413",
    fontSize: 11,
    fontWeight: "bold",
    letterSpacing: 1,
    borderRadius: 4,
    padding: "4px 6px",
    display: "inline-block",
    position: "relative",
    top: -2,
    marginLeft: 10,
    lineHeight: 1,
}

// data
const links = [
    {
        text: "Tutorial",
        url: "https://www.gatsbyjs.com/docs/tutorial/",
        description:
        "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
        color: "#E95800",
    },
    {
        text: "How to Guides",
        url: "https://www.gatsbyjs.com/docs/how-to/",
        description:
        "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
        color: "#1099A8",
    },
    {
        text: "Reference Guides",
        url: "https://www.gatsbyjs.com/docs/reference/",
        description:
        "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
        color: "#BC027F",
    },
    {
        text: "Conceptual Guides",
        url: "https://www.gatsbyjs.com/docs/conceptual/",
        description:
        "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
        color: "#0D96F2",
    },
    {
        text: "Plugin Library",
        url: "https://www.gatsbyjs.com/plugins",
        description:
        "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Build and Host",
        url: "https://www.gatsbyjs.com/cloud",
        badge: true,
        description:
        "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
        color: "#663399",
    },
]

const cstyles = {
    fontSmall: {
        fontSize: "0.8em" 
    },
    navlink: {
        color: '',
        padding: '0.2em',
        margin: '0.2em',
        verticalAlign: 'top',
        display: 'inline-block'
    }
}



// markup
const IndexPage = () => {

    return (
        <div class="container">
        

        {/* class bg-light, navbar, sticky-top */ }
        <nav class="navbar sticky-top d-flex flex-row">
        <div class="p-2">
        <Badge color="secondary" badgeContent={0}><Face /></Badge>
        </div>
        <div class="p-2">
            <a style={cstyles.navlink} href="/aboutme">aboutme</a> 
            <a style={cstyles.navlink} href="/design_patterns">design&nbsp;patterns</a> 
            <a style={cstyles.navlink} href="/math">math</a> 
            <a style={cstyles.navlink} href="/articles">articles</a> 
            <a style={cstyles.navlink} href="/portfolio">portfolio</a> 
        {/* <a class="nav-item" style={cstyles.navlink} href="/photography">photography</a> */}
        </div>
        </nav>
        <hr />
        <p>A place for writing and random bits mostly for myself (maybe someone finds something of interest).</p>  

        <div>

        {/* devicon.dev to see list of icons */}

        {/* List of icons examples
        <h5>Software</h5>
        <div style={{fontSize: 40}} class="devicon-size">
            <i class="devicon-amazonwebservices-plain-wordmark colored"></i>
            <i class="devicon-android-plain"></i>
            <i class="devicon-angularjs-plain colored"></i>
            <i class="devicon-apache-line-wordmark colored"></i>
            <i class="devicon-arduino-plain-wordmark colored"></i>
            <i class="devicon-apple-original colored"></i>
            <i class="devicon-bash-plain colored"></i>
            <i class="devicon-bitbucket-original-wordmark colored"></i>
            <i class="devicon-bootstrap-plain colored"></i>
            <i class="devicon-circleci-plain-wordmark colored"></i>
            <i class="devicon-confluence-original-wordmark colored"></i>
            <i class="devicon-cplusplus-line colored"></i>
        </div>
        */}

        { /*
        <div class="d-flex flex-row">
            <div class="p-2"><Badge color="secondary" badgeContent={0}><Code /></Badge></div>
            <div class="p-2"><a href="/aboutme">/aboutme (new)</a> | <a href="/portfolio">/portfolio</a> | <a href="/articles">/articles</a> | <a href="/photography">/photography</a></div>
        </div>
        */ }


        { /* Sample badge icon */ }
        { /* <div class="p-2"><Badge color="secondary" badgeContent={0}><Coffee /></Badge></div> */ }


        { /* BLOCK REMOVE TABLE
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
        <TableRow>
        <TableCell>Projects</TableCell>
        <TableCell align="right">Description</TableCell>
        <TableCell align="right">Status</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
            <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row"><a href={row.route}>{row.route}</a></TableCell>
            <TableCell align="right">{row.description}</TableCell>
            <TableCell align="right">{row.status}</TableCell>
            </TableRow>
        ))}
        </TableBody>

        </Table>
        </TableContainer>
        <hr />

        */ }

        {/* PENDING REMOVE

        <h3>Recent Books Read</h3>
        <List>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="Possible Minds: 25 Ways of Looking at AI" 
                      secondary  ="John Brockman" />
        </ListItem>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="The Book of Why" 
                      secondary  ="Judea Pearl" />
        </ListItem>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="You Look Like A Thing and I Love You" 
                      secondary  ="Janelle Shane" />
        </ListItem>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="What Hedgefunds Really Do" 
                      secondary  ="Phillip J. Romero" />
        </ListItem>
        </List>

        <p>The first 3 above are related to topics on artificial intelligence, causation, and machine learning.  The last book was about finance and during class we built a stock/market portfolio management tool incorporating some machine learning techniques. </p>

        */}

        <hr />
        <p>For those wondering I'm using React, GraphQL, Markdown, Let's Encrypt, Git Hooks for automated deployments, and Cloudflare for hosting.</p>
        <p>Thank you for visiting on {new Date().toLocaleString()}</p>
        </div>
        { 
        /*
        <p>A quick 3d render, click and drag (up or down)</p>       
        <BabylonScene />
        */ 
        }

    </div>
)
}


export default IndexPage
