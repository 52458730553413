import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../../components/layout.css"

// import '../css/index.css'; // add some style if you want!
export default function Index({ data }) {
    const { edges: posts } = data.allMarkdownRemark
    return (
        <div className="container">
        <div className="row">
        <div className="blog-posts">
        <hr />
        <pre>jpaek.com/design_patterns</pre>
        <p>Soon after graduating Georgia Tech with a masters in computer science I started work as an instructional associate for the software and architecture course.  Having not gotten a chance to really deep dive into every single pattern, I thought I could begin writing a few here and on other related topics.  My hopes are it can serve as a good resource for students in the future semesters and a good opportunity for me to look into each of these.  </p>
        <p>Update: I was invited the next semester to join natural language processing.  This is a new course offering for the online program and excited to join in this exciting intersection of AI and machine learning.  So a pause on these architectural patterns for the time being while I settle in.</p>
        <hr / >
        </div>
        </div>
        <div className="row">
        <div className="col">
        {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
                return (
                    <div className="blog-post-preview" key={post.id}>
                    <h5>
                    <Link to={`/design_patterns${post.frontmatter.path}`}>{post.frontmatter.title}</Link>
                    </h5>
                    <p>{post.frontmatter.date}</p>
                    <p>{post.excerpt}</p>
                    </div>
                )
            })}
        </div>
        <div className="col-4">
        <h4 className="">23 Design Patterns</h4>
        <p>Design pattern are useful if you can recognize a problem fitting to the ones written by Erich Gamma, Richard Helm, Ralph Johnson, and John Vlissides.  They are commonly known as the "Gang Of Four" ("GoF" in literature).  They popularized a book titled "Design Patterns: Elements of Reusable Object-Oriented Software" in 1994.</p>
        <p>So, here are the 23 design patterns introduced which were organized into the following 3 categories. </p>
        <h4 className="">Categories</h4>
        <div className="">
        <h5 className="">Creational Patterns</h5>
        <ul>
        <li>Abstract Factory</li>
        <li>Builder</li>
        <li>Factory Method</li>
        <li>Prototype</li>
        <li>Singleton</li>
        </ul>

        <h5 className="">Structural Patterns</h5>
        <ul>
        <li>Adapater</li>
        <li>Bridge</li>
        <li>Composite</li>
        <li>Decorator</li>
        <li>Facade</li>
        <li>Flyweight</li>
        <li>Proxy</li>
        </ul>
        <h5 className="">Behavorial Patterns</h5>
        <ul>
        <li>Chain of Responsibility</li>
        <li>Command</li>
        <li>Interpreter</li>
        <li>Iterator</li>
        <li>Mediator</li>
        <li>Memento</li>
        <li>Observer</li>
        <li>State</li>
        <li>Strategy</li>
        <li>Template Method</li>
        <li>Visitor</li>
        </ul>
        </div>
        <h5 className="">Additional Topics</h5>
        <ul> 
        <li>Favor Composition Over Inheritance</li>
        </ul>
        </div>
        </div>
        <hr />
        <div style={{minHeight: '4em', backgroundColor: 'black'}}> &nbsp;</div>
        <hr />
        </div> 
    )
}

// Gatsy will introspect this query "myDesignPatternIndexQuery".  This is an index.
export const pageQuery = graphql`
  query myDesignPatternsIndexQuery {
      allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fields: { collection: { eq: "design_patterns" } } }) {
              edges {
                  node {
                      excerpt(pruneLength: 250)
                      id
                      frontmatter {
                          title
                          date(formatString: "MMMM DD, YYYY")
                          path
                      }
                  }
              }
          }
  }
`

